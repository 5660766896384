.home-section {
  padding: 60px 20px;
  text-align: center;
}

.home-container {
  max-width: 1200px;
  margin: 0 auto;
}

.home-hero {
  background-color: #f0f0f0;
  padding: 100px 0;
}

.home-hero .home-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
}

.home-features-list {
  display: flex;
  justify-content: space-around;
}

.home-feature-item {
  flex: 1;
  padding: 20px;
}

.home-cta {
  background-color: #007bff;
  color: white;
  padding: 60px 20px;
}

.home-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  margin: 10px;
  text-decoration: none;
}

.home-btn-secondary {
  background-color: #6c757d;
}

.home-footer {
  background-color: #333;
  color: white;
  padding: 20px;
}

/* Styling für Home Navbar */
.home-navbar {
  display: flex;
  justify-content: space-between;
  background-color: #333;
  padding: 10px 20px;
  position: sticky;
  top: 0;
  z-index: 100;
}

.home-navbar ul {
  display: flex;
  list-style: none;
}

.home-navbar ul li {
  margin: 0 15px;
}

.home-navbar ul li a {
  color: white;
  text-decoration: none;
}

.home-auth-buttons {
  display: flex;
}

.home-auth-buttons .home-btn-small {
  padding: 8px 12px;
  margin: 0 10px;
}

.home-btn-small {
  text-decoration: none;
  color: white;
}

.home-btn-small.home-btn-secondary {
  background-color: #6c757d;
}
