/* Navbar Styling */
.custom-navbar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 60px; /* Eingeklappte Breite */
  background-color: #2d3b50;
  transition: transform 0.3s ease, width 0.3s ease;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  z-index: 10; /* Damit die Navbar über anderen Elementen liegt */
}

.custom-navbar.expanded {
  width: 200px; /* Breitere Navbar, wenn sie ausgeklappt ist */
}

.navbar-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar-list li {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}

.navbar-list li:hover {
  background-color: #1c2d3f;
}

/* Icons Styling */
.icon {
  font-size: 1.5em;
  margin-right: 10px;
  transition: transform 0.3s ease;
}

/* Link Styling für alle Einträge */
.navbar-list a {
  color: white;
  text-decoration: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 10px;
  transition: opacity 0.3s ease;
}

.navbar-list.expanded a {
  opacity: 1;
}

.navbar-list a:hover {
  background-color: #1c2d3f;
  opacity: 1;
}

/* Custom Lists Menu Styling */
.custom-lists-menu {
  position: absolute;
  top: 0;
  left: 100%;
  width: 140px;
  background-color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 8px;
  z-index: 100;
  opacity: 0;
  transform: translateX(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.custom-lists-dropdown:hover .custom-lists-menu {
  opacity: 1;
  transform: translateX(0);
}

.custom-list-item {
  color: #2481e4;
  text-decoration: none;
  padding: 8px;
  font-size: 0.9rem;
  cursor: pointer;
}

.custom-list-item:hover {
  background-color: #e0e0e0;
  color: #2481e4;
}

/* Kategorien Menu Styling */
.categories-menu {
  position: absolute;
  top: 0;
  left: 100%;
  width: 140px;
  background-color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 8px;
  z-index: 100;
  opacity: 0;
  transform: translateX(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.categories-dropdown:hover .categories-menu {
  opacity: 1;
  transform: translateX(0);
}

.category-item {
  color: #2481e4;
  text-decoration: none;
  padding: 8px;
  font-size: 0.9rem;
}

.category-item:hover {
  background-color: #e0e0e0;
  color: #2481e4;
}

/* Subkategorien Dropdown */
.sub-dropdown {
  position: absolute;
  top: 0;
  left: 100%;
  width: 140px;
  background-color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 8px;
  z-index: 200;
  opacity: 0;
  transform: translateX(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.category-item:hover .sub-dropdown {
  opacity: 1;
  transform: translateX(0);
}

.sub-dropdown-item {
  color: #000000;
  text-decoration: none;
  padding: 8px;
  font-size: 0.9rem;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.sub-dropdown-item:hover {
  background-color: #e0e0e0;
  color: #2481e4;
}

/* Logout Button Styling */
.logout-button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.logout-button:hover {
  background-color: #1c2d3f;
}

.category-navbar-title {
  font-size: 1rem;
  margin-bottom: 10px;
  color: #333;
}
.custom-lists-dropdown a {
  color: #333;
}
.Navbar-Icon > a,
.Navbar-Icon > svg {
  color: whitesmoke;
}
