/* Container für die NoQuestions-Komponente */
.no-questions-container {
  text-align: center;
  padding: 30px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 50px auto;
  max-width: 600px;
}

/* Stil für die Überschrift */
.no-questions-title {
  font-size: 2rem;
  color: #333;
  font-weight: 600;
  margin-bottom: 20px;
  text-transform: uppercase;
}

/* Stil für den Text (kann erweitert werden, falls benötigt) */
.no-questions-text {
  font-size: 1.2rem;
  color: #666;
}
