/* Container für das Login-Formular */
.login-container {
  padding: 40px;
  max-width: 400px;
  margin: 50px auto;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Überschrift für das Login-Formular */
.login-container h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

/* Styling für das Formular */
.login-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Label Styling */
.login-form label {
  font-size: 1rem;
  color: #666;
  text-align: left;
  margin-bottom: 5px;
}

/* Eingabefelder Styling */
.login-form input {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.login-form input:focus {
  border-color: #007bff;
  outline: none;
}

/* Login Button Styling */
.login-form button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  font-size: 1.1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.login-form button:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Google Login Abschnitt */
.google-login-section {
  margin-top: 20px;
}

.google-login-section h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}
