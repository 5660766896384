/* Container für die gesamte Seite */
.add-question-type-page-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Überschrift */
.add-question-type-page-title {
  font-size: 2rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600;
}

/* Formular-Styling */
.add-question-type-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 30px;
}

.add-question-type-form label {
  font-size: 1rem;
  color: #444;
  font-weight: 500;
}

.add-question-type-form input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border 0.3s ease;
}

.add-question-type-form input:focus {
  border: 1px solid #007bff;
  outline: none;
}

/* Button-Styling */
.add-question-type-submit-button {
  background-color: #28a745;
  color: #fff;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.add-question-type-submit-button:hover {
  background-color: #218838;
}

/* Liste der Fragetypen */
.card-list-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.card-item {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 10px;
  width: 100%;
  max-width: 300px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;
}

.card-item:hover {
  transform: translateY(-5px);
}

.card-type-text {
  font-size: 1.2rem;
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
}

.card-label-text {
  font-size: 1rem;
  color: #666;
}

/* Nachricht für leere Liste */
.empty-message {
  font-size: 1.2rem;
  color: #999;
  text-align: center;
}
