.dashboard-container {
  padding: 20px;
  background-color: #f4f4f4;
}

h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.dashboard-statistics {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.stat-card {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 22%;
}

.stat-card h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.stat-card p {
  font-size: 1.5rem;
  font-weight: bold;
}

.graph-section {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Dropdown-Menü-Styles */
.topic-selector {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.topic-selector h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

.custom-dropdown {
  position: relative;
  width: 300px;
  margin: 0 auto;
}

.custom-dropdown select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #f9f9f9;
  border: 2px solid #ddd;
  border-radius: 5px;
  color: #333;
  transition: all 0.3s ease;
  cursor: pointer;
}

.custom-dropdown select:focus {
  border-color: #6c63ff;
  box-shadow: 0 0 5px rgba(108, 99, 255, 0.5);
}

.custom-dropdown option {
  background-color: white;
  color: #333;
}

.custom-dropdown select:hover {
  background-color: #fff;
  border-color: #6c63ff;
}

.custom-dropdown-arrow {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 18px;
  color: #6c63ff;
}

.custom-dropdown select::-ms-expand {
  display: none;
}
