.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full screen height */
  }
  
  .spinner {
    width: 50px;
    height: 50px;
    border: 6px solid rgba(0, 0, 0, 0.1);
    border-top-color: #3498db; /* Spinner color */
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }