/* Container für Multiple Answers Input */
.multiple-answers-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}

/* Eingabefeld für jede Antwort */
.answer-input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border 0.3s ease;
}

.answer-input:focus {
  border: 1px solid #007bff;
  outline: none;
}

/* Label für jede Antwort */
.answer-label {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 5px;
  font-weight: 500;
}

/* Antwort-Container */
.answer-item {
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}
