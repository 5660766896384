/* Container für das Passwort-Änderungsformular */
.change-password-container {
  padding: 40px;
  max-width: 400px;
  margin: 50px auto;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Überschrift für das Passwort-Änderungsformular */
.change-password-container h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

/* Styling für das Formular */
.change-password-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Label Styling */
.change-password-form label {
  font-size: 1rem;
  color: #666;
  text-align: left;
  margin-bottom: 5px;
}

/* Eingabefelder Styling */
.change-password-form input {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.change-password-form input:focus {
  border-color: #007bff;
  outline: none;
}

/* Button Styling */
.change-password-form button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  font-size: 1.1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.change-password-form button:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
