/* Styling für den Home-Button */
.home-button {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: background-color 0.3s ease;
}

.home-button:hover {
  background-color: #0056b3;
}

.home-button-icon {
  font-size: 1.5rem;
}
