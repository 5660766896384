/* App Layout */
.app-layout {
  display: flex;
  min-height: 100vh;
  font-family: Arial, sans-serif;
}

.full-width .main-content {
  margin-left: 0; /* Volle Breite für nicht eingeloggt */
  width: 100%; /* Nimmt die gesamte Breite ein */
}

.with-sidebar .main-content {
  margin-left: 60px; /* Platz für eingeklappte Navbar */
  flex: 1; /* Hauptinhalt nimmt den verbleibenden Platz ein */
  transition: margin-left 0.3s ease; /* Smooth transition */
}

.with-sidebar .custom-navbar.expanded ~ .main-content {
  margin-left: 200px; /* Platz für ausgeklappte Navbar */
}
