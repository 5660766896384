/* Styling für den Kategorie-Button */
.categorie-button {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.categorie-button:hover {
  background-color: #0056b3;
}
