.playlist-overview-container {
  padding: 20px;
}

.playlist-section {
  margin-bottom: 40px;
}

.playlist-title {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #333;
}

.category-section {
  margin-bottom: 30px;
}

.category-title {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #555;
}

.subcategory-section {
  margin-bottom: 20px;
}

.subcategory-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #777;
}

.question-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.question-text {
  flex: 1;
  font-size: 1.2rem;
  color: #333;
}

button {
  padding: 8px 12px;
  font-size: 0.9rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.remove-from-playlist {
  background-color: #f44336;
  color: white;
  margin-bottom: 20px;
}

.remove-question {
  background-color: #ff9800;
  color: white;
}

button:hover {
  opacity: 0.8;
}
.playlist-title-button {
  display: flex;
  justify-content: space-between;
}
.playlist-spacer {
  margin-block: 35px;
  border: 0;
  height: 1px;
  background-color: #ddd;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
