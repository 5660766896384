.questions-overview-container {
  padding: 20px;
}

.category-section {
  margin-bottom: 40px;
}

.category-title {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #333;
}

.subcategory-section {
  margin-bottom: 20px;
}

.subcategory-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #555;
}

.question-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.question-text {
  flex: 1;
  font-size: 1.2rem;
  color: #333;
}

.button-group {
  display: flex;
  gap: 10px;
}

button {
  padding: 8px 12px;
  font-size: 0.9rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.edit-button {
  background-color: #4caf50;
  color: white;
}

.delete-button {
  background-color: #f44336;
  color: white;
}

.view-button {
  background-color: #2196f3;
  color: white;
}

button:hover {
  opacity: 0.8;
}
