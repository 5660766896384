.privacy-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  color: #333;
}

.privacy-title {
  font-size: 32px;
  font-weight: bold;
  color: #1a73e8;
  margin-bottom: 10px;
}

.privacy-date {
  font-size: 14px;
  color: #777;
  margin-bottom: 20px;
}

.privacy-subtitle {
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
  color: #444;
}

.privacy-text {
  font-size: 16px;
  line-height: 1.6;
  margin: 10px 0;
}

.privacy-list {
  margin: 15px 0;
  padding-left: 20px;
  list-style-type: disc;
}
